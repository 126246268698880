<template>
    <div>
    <div v-for="item in sliders" v-bind:key="item.data_name">
        <p v-if="item.title === 'Kcal'">{{item.title}} kJ: {{Math.floor(item.range[0]*4.184)}}-{{Math.floor(item.range[1]*4.184)}}</p> 
        <p v-else>{{item.title}} ({{item.measurement}})</p>
        <v-range-slider
            v-model="item.range"
            :max="item.max"
            :min="item.min"
            hide-details
            class="align-center">
            <template v-slot:prepend>
                <v-text-field
                    :value="item.range[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(item.range, 0, $event)"
                ></v-text-field>
            </template>
            <template v-slot:append>
                <v-text-field
                    :value="item.range[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(item.range, 1, $event)"
                ></v-text-field>
            </template>
        </v-range-slider>
    </div>
    <v-expansion-panels accordion>
        <v-expansion-panel
            v-for="(item,i) in 1"
            :key="i"
        >
            <v-expansion-panel-header>Flere næringstyper</v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-for="item in overflowSliders" v-bind:key="item.id">
                <p>{{item.title}} ({{item.measurement}})</p>
                <v-range-slider
                    v-model="item.range"
                    :max="item.max"
                    :min="item.min"
                    hide-details
                    class="align-center">
                    <template v-slot:prepend>
                        <v-text-field
                            :value="item.range[0]"
                            class="mt-0 pt-0"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(item.range, 0, $event)"
                        ></v-text-field>
                            </template>
                            <template v-slot:append>
                                <v-text-field
                                    :value="item.range[1]"
                                    class="mt-0 pt-0"
                                    hide-details
                                    single-line
                                    type="number"
                                    style="width: 60px"
                                    @change="$set(item.range , 1, $event)"
                                ></v-text-field>
                            </template>
                    </v-range-slider>
                </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div class="pb-4">
            <v-btn 
                block
                color="primary"
                elevation="3"
                @click="searchIngredientsByRange"
            >
            Søk på ingredienser
            </v-btn>
        </div>
        <v-list three-line
        >
            <v-list-item
              :key="ingredient.product+index"
              v-for="(ingredient, index) in ingredients"
            >

              <v-list-item-content>
                <v-list-item-title> <strong>{{ingredient.product}}</strong><span v-for="keyword in ingredient.keywords" v-bind:key="keyword"> {{keyword}}</span></v-list-item-title>
                <v-list-item-subtitle><span v-for="nutrient in ingredient.nutrientInfo" class="mx-2" v-bind:key="nutrient.title+index">
                    <span class="text--primary">{{nutrient.title}}</span> {{nutrient.value}}{{nutrient.measurement}}
                </span></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-list>
        <!--<div v-for="ingredient in ingredients">
            <strong>{{ingredient.product}}</strong><span v-for="keyword in ingredient.keywords"> {{keyword}}</span>
        </div>-->
    </div>
</template>
<script>
    
    

    export default {
        data (){
            return {
                sliders:[],
                overflowSliders:[],
                ingredients:[]
            };
        },
        created () {
            this.$axios.get('https://api.brukmat.no/datascheme/nutrients').then((res)=>{
                let data = res.data.data;
                let sliders = [];
                for(let property in data){
                    let dataObj = data[property];
                    // Default is g/%, which is 100
                    var min = 0;
                    var max = 100;
                    if(dataObj.multiplier){
                        max*=dataObj.multiplier;
                    } else {
                        switch(dataObj.measurement){
                            case 'mg':
                            // Multiply by 100 (=10000)
                            max*=10;
                            break;
                        }
                    }
                    sliders.push({
                        title:dataObj.title,
                        measurement:dataObj.measurement,
                        category:dataObj.category || 'Annet',
                        data_name:property,
                        max:max,
                        min:min
                    });
                }
                sliders.sort((a, b)=>{
                    if(a.category.toLowerCase() !== "makro" && b.category.toLowerCase() === "makro"){
                        return 1;
                    }
                    if(a.category.toLowerCase() === "makro" && b.category.toLowerCase() !== "makro"){
                        return -1;
                    }
                    return 0;
                });

                /*var sliders = [
                    {title:"kCal", min:0, max:900}, 
                    {title:"Protein", min:0,max:100}, 
                    {title:"nutrients_fats_total", min:0, max:100},
                    {title:"nutrients_fats_trans", min:0, max:100},
                    {title:"nutrients_fats_umettet", min:0, max:100}
                ];*/
                var slidersMapped = sliders.map(item=>{
                    let returnValue = {};
                    returnValue.title = item.title;
                    // Reactive elements
                    if(!returnValue.range) returnValue.range = {};
                    returnValue.range = [item.min, item.max];
                    // Default values
                    returnValue["min"] = item.min;
                    returnValue["max"] = item.max;
                    returnValue.measurement = item.measurement;
                    returnValue.category = item.category;
                    returnValue.data_name = item.data_name;
                    return returnValue;
                }, []);
                var mainSliders = slidersMapped.filter((el)=>{
                    //console.log(el);
                    return el.category.toLowerCase() === "makro";
                });
                var overflowSliders = slidersMapped.filter((el)=>{
                    return el.category.toLowerCase() !== "makro";
                });

                this.sliders = mainSliders;
                this.overflowSliders = overflowSliders;
            });
        },
        watch:{
            
        },
        methods:{
            searchIngredientsByRange(){
                let allSliders = [...this.sliders, ...this.overflowSliders];
                // Check for changes
                let allChangedSliders = allSliders.filter((el)=>{
                    return el.range[0] !== el.min || el.range[1] !== el.max;
                });
                //console.log(allChangedSliders);
                let mappedData = allChangedSliders.map((sliderData)=>{
                    return {
                        from:sliderData.range[0],
                        to:sliderData.range[1],
                        property:sliderData.data_name
                    };
                });
                let headers = {
                  'Content-Type': 'application/json'
                };
                this.$axios.post('https://api.brukmat.no/ingredients/range/',{"page":0, "criteria":mappedData}, {"headers":headers})
                .then(res=>{
                    console.log("Returned data",res);
                    let data = res.data.data;
                    //let dataToStore = data;

                    this.ingredients = data.map(ingredient=>{
                        let nutrientInfo = allChangedSliders.map(changed=>{
                            let value = ingredient["nutrients_"+changed.data_name];
                            return {
                                value:value,
                                title:changed.title,
                                measurement:changed.measurement
                            };
                        })
                        //console.log(nutrientInfo);
                        return {...ingredient, nutrientInfo:nutrientInfo};
                    });
                });
                console.log(mappedData);
            }
        }
    }
</script>